body {
  margin: 0;
  font-family: Tahoma;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fontfarsi {
  font-family: "farsi" !important;
}
.fonttahoma{
  font-family: Tahoma !important;
}
.fontarial{
  font-family: Arial !important;
}
